@import 'styles/variables';
@import 'styles/bootstrap';

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

body {
  font-family: 'Poppins', sans-serif;
  background: #eee;
}

#SavingButton {
    position: fixed;
    left: 6%;
    top: 184px;

    &.sticky {
      top: 20px !important;
    }
}

.spinner-border {
  color: #ccc;
}

.question.spinner-border {
  color: #f1f1f1;
}

.App {
  display:  flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}

.nav-bar {
  position: relative;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.row.mb-5 {
  margin: 5rem 0 2rem !important;
}

.header {
    background: #F8F9FA;
}

.termsConditions {
  max-height: 20vh;
  overflow: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 80%;
  font-weight: 400;
}

.fixedfooter {
  position: fixed;
  left: 0;
  bottom: -20px;
  z-index: -1;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-tabs {
    background: $blue;
    padding: 10px;
    color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: darken($blue, 10%);
}

.nav-link {
  color: #fff;
}

.form-copy {
    background: rgba(255, 255, 255, 0.95);
    padding: 0 20px 20px;
}

.upload-field {
  padding: 1em;
  border: 2px dashed #019ABE;
  background: #f1f1f1;

  label {
    margin-bottom: 1.5em;
    font-weight: bold;
  }
}

.save {
  position: absolute;
  left: -30%;
  top: -45px;
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 2em;
  height: 2em;
  display: block;
  margin: 0 auto;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #fff;
}

.svg-icon circle {
  stroke: #fff;
  stroke-width: 1;
}

.svg-icon.check {

  width: 4em;
  height: 4em;
  display: block;
  margin: 0 auto;

  path,
  polygon,
  rect {
    fill: green;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Media Queries */

/* Small Devices */

@media (max-width: 768px) {  

  body .row {
    margin: 0 auto;
  }

  body .nav-bar {
    position: fixed;
  }

  body .App {
    flex-direction: column;
  }
}